import {useState} from 'react'
import styles from './home.module.css'
import {Buffer} from 'buffer'

// export const baseURL = 'http://localhost:8080'
// export const meURL = 'http://localhost:3000'
export const baseURL = 'https://api.cyclistanalytics.com'
export const meURL = 'https://cyclistanalytics.com'

export const AK = [
  0x9d - 0x39,
  0x47 + 0x1a,
  0x83 - 0x17,
  0x8b - 0x18,
  0x7c - 0x16,
  0xd0 - 0x65,
  0x70 - 0x6,
  0x03 + 0x5e,
  0xbc - 0x49,
  0x76 - 0x12,
  0x2b + 0x3b,
  0xaa - 0x3e,
  0x99 - 0x2e,
  0xa6 - 0x3c,
  0x80 - 0x1f,
  0xab - 0x47,
  0xe2 - 0x6f,
  0x9a - 0x34,
  0x40 + 0x21,
  0x0f + 0x55,
  0x8a - 0x17,
  0x60 - 0x2c,
  0x4a - 0x17,
  0x30 + 0x4,
  0x41 + 0x2b,
  0x63 - 0x30,
  0xd7 - 0x6b,
  0x4b + 0x20,
  0x3d - 0x9,
  0x2f + 0x4,
  0x35 + 0x37,
  0x0e + 0x56,
  0x1a + 0x59,
  0xb9 - 0x4e,
  0x5c + 0xe,
  0x97 - 0x31,
  0x2f + 0x32,
  0xc6 - 0x62,
  0x91 - 0x26,
  0x42 + 0x22,
  0x42 + 0x28,
  0x8c - 0x26,
  0x2f + 0x3b,
  0x1f + 0x42,
  0x69 + 0xa,
  0x00 + 0x64,
  0xb7 - 0x4b,
  0x9d - 0x32,
  0x52 + 0xf,
  0xa5 - 0x32,
  0xc7 - 0x63,
  0x99 - 0x33,
  0xd8 - 0x6c,
  0x50 + 0x1b,
  0xc0 - 0x56,
  0x89 - 0x28,
  0x63 + 0x10,
  0x8b - 0x27,
  0x56 + 0x10,
  0x8d - 0x21,
  0x20 + 0x4b,
  0xa7 - 0x3d,
  0x3c + 0x37,
  0x24 + 0x40,
  0xac - 0x40,
  0xce - 0x63
]

export default function Home() {
  const [email, setEmail] = useState('')
  const [fullName, setFullName] = useState('')
  const [errorMsg, setErrorMsg] = useState('')
  const [disabled, setDisabled] = useState(false)

  const handleInputChange = (event) => {
    if (event.currentTarget.name === 'email') {
      setEmail(event.target.value)
    } else {
      setFullName(event.target.value)
    }
  }

  const trimAndRemoveStrangeCharacters = (inputString) => {
    // Trim the input string
    let trimmedString = inputString.trim()
    // Remove all non-alphanumeric characters using a regular expression
    let cleanString = trimmedString.replace(/[^a-zA-Z0-9 ]/g, '')

    return cleanString
  }

  const validateInputs = async () => {
    if (!disabled) {
      setDisabled(true)
      const emailPattern =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

      if (emailPattern.test(email) && fullName.trim().length > 0) {
        setErrorMsg('')
        await connectWithGarmin()
      } else {
        setErrorMsg('Please fill properly the requested values')
      }
      setDisabled(false)
    }
  }

  const connectWithGarmin = async () => {
    let cleanFullName = trimAndRemoveStrangeCharacters(fullName)
    localStorage.setItem('email', email.trim())
    localStorage.setItem('fullName', cleanFullName)

    var myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')

    var raw = JSON.stringify({
      APIKey: Buffer.from(AK).toString('base64'),
      email,
      fullName: cleanFullName
    })

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    }

    try {
      let response = await fetch(
        `${baseURL}/garmin/garminconnect`,
        requestOptions
      )
      if (response.ok) {
        let result = await response.text()
        window.location.href = decodeURIComponent(result)
      } else {
        alert("It's not being possible to communicate with the server")
      }
    } catch (error) {
      alert(error.reason ? error.reason : error)
    }
  }

  return (
    <main className={styles.main}>
      <div className={styles.logo}>
        <img
          src="/jumbo_leaseabike.png"
          alt="Team Visma Lease a bike"
          className={styles.logoimg}
        />
      </div>
      <h2>
        The objective of this webpage is to grant authorization to the "Cyclist
        Analytics" application to analyze your cycling data with the intention
        of enhancing your performance.
      </h2>
      <p>
        If you consent to granting authorization for "Cyclist Analytics" to
        analyze your data, kindly complete the required fields and click on the
        Garmin logo. This action will redirect you to a Garmin webpage where you
        will be prompted to input your credentials.
      </p>
      <p className={styles.contact}>Contact person: Jon Iriberri</p>
      <p className={styles.contact}>
        Contact email:
        <a
          className={styles.email}
          href="mailto:jon.iriberri@teamvismaleaseabike.com"
        >
          jon.iriberri@teamvismaleaseabike.com
        </a>
      </p>
      <div className={styles.personaldata}>
        {errorMsg !== '' ? (
          <div className={styles.errormsg}>
            <p>{errorMsg}</p>
          </div>
        ) : null}
        <div className={styles['personaldata-field']}>
          <label htmlFor="email">Email Address:</label>
          <input
            type="text"
            id="email"
            name="email"
            value={email}
            onChange={handleInputChange}
          />
        </div>
        <div className={styles['personaldata-field']}>
          <label htmlFor="fname">Full name:</label>
          <input
            type="text"
            id="fname"
            name="fname"
            value={fullName}
            onChange={handleInputChange}
          />
        </div>
      </div>
      <div className={styles.buttoncontainer} onClick={validateInputs}>
        <img
          src="/garmin.png"
          alt="Connect to Garmin"
          className={styles.garminlogoimg}
        />
        <div className={styles.buttonoverlay}></div>
      </div>
    </main>
  )
}
