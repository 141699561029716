import styles from './privacypolicy.module.css'

export default function PrivacyPolicy() {
  return (
    <main className={styles.main}>
      <h1>Política de Privacidad</h1>

      <p>
        En Cyclist Analytics, nos tomamos muy en serio la privacidad de nuestros
        usuarios. Esta Política de Privacidad describe cómo recopilamos,
        utilizamos, compartimos y protegemos la información personal que
        obtenemos a través de nuestro sitio web.
      </p>

      <h2>Información que recopilamos</h2>
      <p>
        Recogemos el nombre, el correo electrónico y los datos de las
        actividades físicas realizados a través de Garmin, así como datos de
        posibles estudios realizados para comprobar el coeficiente aerodinámico
        de los atletas.
      </p>

      <h2>Cómo utilizamos la información</h2>
      <p>
        Utilizamos la información para mejorar e informar del rendimiento del
        deportista al equipo técnico. Posibilitando el estudio de los datos
        generados y convirtiéndolos en información
      </p>

      <h2>Compartir información con terceros</h2>
      <p>
        La información nunca será compartirdo con personal externo al equipo
        técnico que conforma la aplicación Cyclist Analytics
      </p>

      <h2>Seguridad de la información</h2>
      <p>
        El correo electrónico y el nombre del deportista se guardarán de forma
        cifrada y ánonima, no pudiendo ser idenficado el deportista. De esta
        forma se consigue un anonimato total de los datos
      </p>

      <h2>Enlaces a otros sitios web</h2>
      <p>
        Esta aplicación contiene enlaces a la plataforma de Garmin Connect, los
        mecanismos mencionados de la política de privacidad solo se aplican a
        Cyclist Analytics y los deportistas deben leer las políticas de
        privacidad de dicho sitio.
      </p>

      <h2>Cambios a esta política de privacidad</h2>
      <p>
        Estás políticas de privacidad pueden sufrir futuros cambios que serán
        notificados por correo electrónico a los usuarios.
      </p>

      <h2>Contacto</h2>
      <p>El correo electrónico de contacto es info@cyclistanalytics.com .</p>

      <p>
        Esta política de privacidad fue actualizada por última vez el
        24/05/2023.
      </p>
    </main>
  )
}
