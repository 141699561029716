// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".privacypolicy_main__gzWFD {\n  color: #333;\n  text-align: left;\n  line-height: 1.6;\n  padding-left: 10rem;\n  padding-right: 10rem;\n  padding-top: 5rem;\n  min-height: 100vh;\n}\n\n.privacypolicy_main__gzWFD h1 {\n  font-size: 36px;\n  color: #007bff;\n}\n\n.privacypolicy_main__gzWFD h2 {\n  font-size: 28px;\n  color: #555;\n  margin-top: 30px;\n}\n\n.privacypolicy_main__gzWFD p {\n  color: #777;\n}", "",{"version":3,"sources":["webpack://./src/scenes/PrivacyPolicy/privacypolicy.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,gBAAgB;EAChB,gBAAgB;EAChB,mBAAmB;EACnB,oBAAoB;EACpB,iBAAiB;EACjB,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,WAAW;AACb","sourcesContent":[".main {\n  color: #333;\n  text-align: left;\n  line-height: 1.6;\n  padding-left: 10rem;\n  padding-right: 10rem;\n  padding-top: 5rem;\n  min-height: 100vh;\n}\n\n.main h1 {\n  font-size: 36px;\n  color: #007bff;\n}\n\n.main h2 {\n  font-size: 28px;\n  color: #555;\n  margin-top: 30px;\n}\n\n.main p {\n  color: #777;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": "privacypolicy_main__gzWFD"
};
export default ___CSS_LOADER_EXPORT___;
