import React from 'react'

const NotFound = () => {
  return (
    <div style={styles.container}>
      <img
        src="/cyclistanalytics.png"
        alt="Cyclist Analytics logo"
        width={250}
        height={250}
      />
      <h1 style={styles.heading}>NOT FOUND</h1>
      <p style={styles.message}>The page you're looking for does not exist.</p>
    </div>
  )
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    fontFamily: 'Arial, sans-serif'
  },
  heading: {
    fontSize: '4rem',
    marginBottom: '1rem',
    color: '#333'
  },
  message: {
    fontSize: '1.5rem',
    color: '#777'
  }
}

export default NotFound
