import {useEffect} from 'react'
import styles from './garminconnect.module.css'
import {useLocation} from 'react-router-dom'
import {AK, baseURL, meURL} from '../Home/Home'
import {Buffer} from 'buffer'

export default function GarminConnect() {
  const location = useLocation()

  useEffect(() => {
    const saveGarminCredentials = async (oauthToken, oauthVerifier) => {
      var myHeaders = new Headers()
      myHeaders.append('Content-Type', 'application/json')

      var raw = JSON.stringify({
        APIKey: Buffer.from(AK).toString('base64'),
        oauthToken,
        oauthVerifier,
        email: localStorage.getItem('email')
      })

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      }

      try {
        let response = await fetch(
          `${baseURL}/garmin/savegarmincredentials`,
          requestOptions
        )
        if (!response.ok) {
          alert("It's not being possible to communicate with the server")
          window.location.href = meURL
        }
      } catch (error) {
        alert(error.reason ? error.reason : error)
      }
    }

    const url = `${baseURL}/${location.pathname}${location.search}`
    debugger
    const queryParams = new URLSearchParams(new URL(url).search)
    const oauthToken = queryParams.get('oauth_token')
    const oauthVerifier = queryParams.get('oauth_verifier')
    saveGarminCredentials(oauthToken, oauthVerifier)
  }, [location.pathname, location.search])

  return (
    <main className={styles.main}>
      <h1>
        Your Garmin account is now successfully connected with "Cyclist
        Analytics."
      </h1>
      <p>
        As a result, your Garmin activities will be processed and made available
        to the performance team for analysis.
      </p>
    </main>
  )
}
