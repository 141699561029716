// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".garminconnect_main__1BKq3 {\n  color: #333;\n  text-align: center;\n  line-height: 1.6;\n  padding-left: 10rem;\n  padding-right: 10rem;\n  padding-top: 10rem;\n  min-height: 100vh;\n}\n\n.garminconnect_main__1BKq3 h1 {\n  font-size: 36px;\n  color: #007bff;\n}\n\n.garminconnect_main__1BKq3 p {\n  color: #777;\n  font-size: 1.3em;\n}", "",{"version":3,"sources":["webpack://./src/scenes/GarminConnect/garminconnect.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,oBAAoB;EACpB,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB","sourcesContent":[".main {\n  color: #333;\n  text-align: center;\n  line-height: 1.6;\n  padding-left: 10rem;\n  padding-right: 10rem;\n  padding-top: 10rem;\n  min-height: 100vh;\n}\n\n.main h1 {\n  font-size: 36px;\n  color: #007bff;\n}\n\n.main p {\n  color: #777;\n  font-size: 1.3em;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": "garminconnect_main__1BKq3"
};
export default ___CSS_LOADER_EXPORT___;
